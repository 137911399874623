// extracted by mini-css-extract-plugin
export var aArrow1 = "na";
export var aArrow2 = "oa";
export var aButton1 = "da";
export var aButton2 = "ea";
export var aButton3 = "fa";
export var aButton4 = "ga";
export var aButton5 = "ha";
export var aButtonHeaderLogin = "ia";
export var aButtonHeaderRegister = "ja";
export var aButtonHeaderSpLogin = "ma";
export var aButtonLogin = "la";
export var aButtonRegister = "ka";
export var aButtons = "ya";
export var aCategory = "ba";
export var aCategoryGray = "aa";
export var aDate = "ca";
export var aDetail = "W";
export var aFullImage = "xa";
export var aLabel = "X";
export var aLink = "Z";
export var aTags = "za";
export var aText = "ra";
export var aTitle = "U";
export var aTitle1 = "wa";
export var animationMarquee = "Da";
export var lSection = "pa";
export var lSection__column2 = "ta";
export var lSection__column2Border = "sa";
export var lSection__content = "ua";
export var lSection__contentNoBorder = "va";
export var lSection__figureBox = "Ca";
export var lSection__header = "V";
export var lSection__headerNoborder = "T";
export var lSection__iframe = "Ba";
export var lSection__iframeBorder = "Aa";
export var lSection__inner = "qa";
export var lSection__more = "_";
export var lSection__moreNoborder = "Y";